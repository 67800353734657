canvas{
    font-family: monospace;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fcfff4+0,9e9d8c+100 */
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#feffff+0,ddf1f9+35,638293+100 */
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#feffff+27,638293+100 */
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#feffed+0,adb6c9+100 */
background: rgb(254,255,237); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(254,255,237,1) 0%, rgba(173,182,201,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(254,255,237,1) 0%,rgba(173,182,201,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(254,255,237,1) 0%,rgba(173,182,201,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#feffed', endColorstr='#adb6c9',GradientType=0 ); /* IE6-9 */

}

#container{
    position: relative;
}

#container canvas, #popup, #text{
    position: absolute;
}

#popup{
    position: absolute;
    background-color: #a3a2a293;
    display: block;
    width: 500px;
    height: 100px;
    width: 40%;
    left: 30%;
    top: 350px;
    outline: auto;
    outline-color: #c9c9c9;
    outline-offset: 5px;
    border-radius: 20px;
    
}

#text{
    position: relative;
    color: #000000;
    margin: 0;
    font: Arial;
    font-size: 20px;
    text-align: center;
    top: 30%;
}
